import {ReactNode, useState, useEffect, useRef} from "react";
import styles from "./CatalogItemsHight.module.scss";
import {Arrow} from "../../../icons";
import {CatalogItemsLow} from "../CatalogItemsLow/CatalogItemsLow";

export function CatalogItemsHight({
                                      curPath,
                                      choosenCategory,
                                      setChoosenCategory,
                                      name,
                                      onClick,
                                      children,
                                  }: {
    curPath: string
    choosenCategory: string
    setChoosenCategory: (v: string) => void;
    name: string;
    onClick: () => void;
    children: ReactNode;
}) {
    const [visible, setVisible] = useState(false);
    const [visibleClick, setVisibleClick] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setVisible(false); // Скрываем элементы при клике вне компонента
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setVisible((prevVisible) => !prevVisible);
    };
    console.log(curPath)
    //@ts-ignore
    return (
        <>
            <div
                //ref={containerRef}
                onMouseEnter={() => setChoosenCategory(curPath)}
                // onMouseLeave={() => {
                //     if (!visibleClick) {
                //         setVisible(false)
                //     }
                // }}
                className={styles.container}>
                <div className={styles.item}
                     onClick={() => setVisibleClick(prevState => !prevState)}
                >
                    <p>{name}</p>
                    <Arrow width="15px"/>
                </div>
            </div>
            {choosenCategory.includes(name) && <div onMouseEnter={() => setVisible(true)}
                                              onMouseLeave={() => setVisible(false)}
                //@ts-ignore
                                              className={styles.list_items}
                //@ts-ignore
                                              style={children.length > 12 ? {right: "-520px"} : {right: "-260px"}}>
                <CatalogItemsLow name="все товары категории" onClick={onClick}/>
                {children}
            </div>}
        </>
    );
}
