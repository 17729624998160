import {ReactNode, useState, useEffect, useRef} from "react";
import styles from "./CatalogItemsHight.module.scss";
import {Arrow} from "../../../icons";
import {CatalogItemsLow} from "../CatalogItemsLow/CatalogItemsLow";

export function CatalogItemsHightMobile({
                                            name,
                                            onClick,
                                            children,
                                            className,
                                        }: {
    name: string;
    onClick?: () => void;
    children: ReactNode;
    className?: string;
}) {
    const [visible, setVisible] = useState(false)
    //@ts-ignore
    return (
        <>
            <div
                //ref={containerRef}
                className={`${styles.container}`}>
                <div className={`${styles.item_mobile} ${className ? className : ""}`}
                     onClick={() => setVisible(true)}
                >
                    <p>{name}</p>
                    <Arrow width="15px"/>
                </div>
            </div>
            {visible && <div
                //@ts-ignore
                className={styles.list_items_mobile}>
                <div className={styles.item_back_mobile}
                     onClick={() => setVisible(false)}
                >
                    <Arrow width="15px"/>
                    <p>{name}</p>
                </div>
                {onClick && <CatalogItemsLow mobile={true} name="все товары категории" onClick={onClick}/>}
                {children}
            </div>}
        </>
    );
}
