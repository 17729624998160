import React from "react";
import { INavLinkType } from "../../../../types/appTypes";
import { Link } from "react-router-dom";

const mainNavData: INavLinkType[] = [
  {
    id: 1,
    link: "/brands",
    text: "Бренды",
  },
  // {
  //   id: 2,
  //   link: "/gifts",
  //   text: "Подарочные карты",
  // },
  {
    id: 3,
    link: "/promotion",
    text: "Акции",
  },
  {
    id: 4,
    link: "/markets",
    text: "Магазины",
  },
];

const MainNav: React.FC = (): JSX.Element => {
  // const [burgerOpened, setBurgerOpened] = React.useState<boolean>(false);

  return (
    <div style={{zIndex: 50}} className=" md:pt-5 max-w-[612px] mx-auto">
      <nav className={`md:block nav main__nav`}>
        <ul className="main__nav-menu flex flex-col justify-between  md:flex-row">
          {mainNavData.map((elem: INavLinkType) => (
            <li key={elem.id} className="main__nav-list_item">
              <Link
                to={elem.link}
                className="main__nav-link text-base font-medium text-primary hover:text-secondary duration-300"
                // onClick={()=>{setBurgerOpened(false)}}
              >
                {elem.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MainNav;
