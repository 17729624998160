import React from "react";
import { IconProps } from "../../types/appTypes";

const SearcherIcon: React.FC<IconProps> = ({
                                             className,
                                             color,
                                             height,
                                             width,
                                           }: IconProps): JSX.Element => {
  return (
      <svg
          width={width ? width : "25"}
          height={height ? height : "25"}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
      >
        <g clipPath="url(#clip0_554_13754)">
          <path
              d="M9.52083 17.488C13.9161 17.488 17.4792 13.9249 17.4792 9.52962C17.4792 5.13436 13.9161 1.57129 9.52083 1.57129C5.12557 1.57129 1.5625 5.13436 1.5625 9.52962C1.5625 13.9249 5.12557 17.488 9.52083 17.488Z"
              stroke={color ? color : "#070707"}
              strokeWidth="1.5"
              strokeMiterlimit="10"
          />
          <path
              d="M23.4372 23.446L14.9893 14.998"
              stroke={color ? color : "#070707"}
              strokeWidth="1.5"
              strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_554_13754">
            <rect
                width="25"
                height="25"
                fill="white"
                transform="translate(0 0.00878906)"
            />
          </clipPath>
        </defs>
      </svg>
  );
};

export default SearcherIcon;
