import React, {useState} from "react";
import {ModalsType} from "../../../types/appTypes";
import {Modal} from "antd";
import SubmitBtn from "../../forms/btns/SubmitBtn/SubmitBtn";
import Input from "../../forms/Input/Input";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    changeBirthdayAC,
    changeEnterPassAC,
    changePhonNumAC,
    changeUserNameAC, changeUserSurnameAC,
    setVisibleLoginModalAC,
    setVisibleSignUpModalAC,
} from "../../../store/userSlice";
import {checkPhoneForRegister} from "../../../api/userApi";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {inspect} from "util";
import styles from "./SignUpModal.module.scss"

interface SignupType {
    userName: string;
    userSurname: string;
    phoneNum: string;
    password: string;
    birthday: Date;
}

// const schema = yup.object({
//     userName: yup.string().required("обязательное поле").min(3, "минимум 3 символа"),
//     phoneNum: yup.string().required("обязательное поле").min(8, "минимум 8 символов").max(24, "максимум 24 символов"),
//     password: yup.string().required("обязательное поле")
//         .min(8, "минимум 8 символов").max(20, "максимум 20 символов")
//         .matches(/\d+/, "должен содержать хотяб одну цифру")
//         .matches(/[a-z]+/, "не содержит нижний регистр (a-z)")
//         .matches(/[A-Z]+/, "не содержит заглавные буквы (A-Z)")
//         .matches(/[!@#$%^&*()-+]+/, "не содержит спец символы (!@#$%)")
//         .test("no-spaces", "не должен содержать пробелы", (value) => !/\s+/.test(value)),
//     birthday: yup.date().required("обязательное поле")
//         .max(new Date(), "Дата не может быть больше сегодняшней")
//         .min(new Date(1900, 0, 1), "Дата не может быть раньше 1 января 1900 года"),
// });

const SignUpModal: React.FC<ModalsType> = ({open, setOpen}): JSX.Element => {
    const dispatch = useAppDispatch();
    const {phoneNum, userName, userSurname, enterPass, birthday, fetchingCheckPhoneRes} =
        useAppSelector((state) => state.userSlice);

    // const {
    //     handleSubmit,
    //     control,
    //     formState: { errors },
    //     setValue,
    // } = useForm<SignupType>({
    //     resolver: yupResolver(schema),
    // });

    const formatDate = (date: Date) => date.toISOString().split('T')[0];

    const handleSignup = () => {
        dispatch(checkPhoneForRegister({phone: phoneNum, is_send_sms: true}));
    };
    const [error, setError] = useState({
        username: "",
        phoneNum: "",
        password: "",
        birthday: ""
    })
    const validate = () => {
        const newErrors = {
            username: "",
            phoneNum: "",
            password: "",
            birthday: "",
        };

        let isValid = true;

        // Validate userName
        if (!userName.trim()) {
            newErrors.username = "обязательное поле"; // Required field
            isValid = false;
        } else if (userName.trim().length < 3) {
            newErrors.username = "минимум 3 символа"; // Minimum length
            isValid = false;
        }
        // Validate phoneNum
        if (!phoneNum.trim()) {
            newErrors.phoneNum = "обязательное поле"; // Required field
            isValid = false;
        } else if (phoneNum.trim().length < 8 || phoneNum.trim().length > 24) {
            newErrors.phoneNum = "минимум 8 и максимум 24 символов"; // Length validation
            isValid = false;
        }

        // Validate password (use enterPass instead of password)
        if (!enterPass.trim()) {
            newErrors.password = "обязательное поле"; // Required field
            isValid = false;
        } else if (enterPass.trim().length < 6 || enterPass.trim().length > 20) {
            newErrors.password = "минимум 6 и максимум 20 символов"; // Length validation
            isValid = false;
        }
        // else if (!/\d/.test(enterPass)) {
        //     newErrors.password = "должен содержать хотя бы одну цифру"; // Digit check
        //     isValid = false;
        // } else if (!/[a-z]/.test(enterPass)) {
        //     newErrors.password = "не содержит нижний регистр (a-z)"; // Lowercase check
        //     isValid = false;
        // } else if (!/[A-Z]/.test(enterPass)) {
        //     newErrors.password = "не содержит заглавные буквы (A-Z)"; // Uppercase check
        //     isValid = false;
        // } else if (!/[!@#$%^&*()\-+]/.test(enterPass)) {
        //     newErrors.password = "не содержит спец символы (!@#$%)"; // Special character check
        //     isValid = false;
        // } else if (/\s/.test(enterPass)) {
        //     newErrors.password = "не должен содержать пробелы"; // No spaces check
        //     isValid = false;
        // }

        // Validate birthday
        const birthdayDate = new Date(birthday); // Convert to Date object
        if (!birthday) {
            newErrors.birthday = "обязательное поле"; // Required field
            isValid = false;
        } else if (birthdayDate > new Date()) {
            newErrors.birthday = "Дата не может быть больше сегодняшней"; // Max date validation
            isValid = false;
        } else if (birthdayDate < new Date(1900, 0, 1)) {
            newErrors.birthday = "Дата не может быть раньше 1 января 1900 года"; // Min date validation
            isValid = false;
        }
        if(isValid) {
            handleSignup()
        }
        setError(newErrors);
        return isValid;
    };


    return (
        <Modal
            footer={null}
            visible={open}
            onCancel={() => setOpen(false)}
            destroyOnClose={true}
        >
            <div className="pl-10 pr-10 pt-[50px] py-10 pb-10">
                <h4 className="mb-5 text-xl font-semibold md:text-[32px] text-primary">
                    Зарегистрироваться
                </h4>
                <form>
                    <div>
                        <div className="my-2.5">
                            <Input
                                inpClassName={error.username ? styles.error : ""}
                                inpId=""
                                onChange={(v) => {
                                    setError(prevState => ({...prevState, username: ""}))
                                    dispatch(changeUserNameAC(v));
                                }}
                                value={userName}
                                disabled={false}
                                inpType="text"
                                labelTxt="Имя Фамилия"
                                errors={error.username}
                            />
                        </div>
                        <div className="my-5">
                            <Input
                                inpClassName={error.phoneNum ? styles.error : ""}
                                inpId=""
                                onChange={(v) => {
                                    setError(prevState => ({...prevState, phoneNum: ""}))
                                    dispatch(changePhonNumAC(v));
                                }}
                                value={phoneNum}
                                disabled={false}
                                inpType="tel"
                                labelTxt="Номер телефона"
                                errors={error.phoneNum}
                            />
                        </div>
                        <div className="my-5">
                            <Input
                                inpClassName={error.birthday ? styles.error : ""}
                                inpId=""
                                onChange={(v) => {
                                    setError(prevState => ({...prevState, birthday: ""}))
                                    dispatch(changeBirthdayAC(v));
                                }}
                                value={birthday ? birthday : ""}
                                disabled={false}
                                inpType="date"
                                labelTxt="Дата рождения"
                                errors={error.birthday}
                            />
                        </div>
                        <div className="my-5">
                            <Input
                                inpClassName={error.password ? styles.error : ""}
                                inpId=""
                                onChange={(v) => {
                                    setError(prevState => ({...prevState, password: ""}))
                                    dispatch(changeEnterPassAC(v));
                                }}
                                value={enterPass}
                                disabled={false}
                                inpType="password"
                                labelTxt="Пароль"
                                errors={error.password}
                            />
                        </div>
                        <div className="my-[30px]">
                            <SubmitBtn
                                onClick={() => {
                                    validate()
                                }}
                                className=""
                                txt="Получить код"
                                //disabled={!phoneNum || !enterPass || !userName || !birthday}
                                loading={fetchingCheckPhoneRes}
                            />
                        </div>
                    </div>
                </form>
                <div>
                    <div className="text-sm mb-5">
                        Уже зарегистрированы?{" "}
                        <button
                            className="text-secondary underline"
                            onClick={() => {
                                dispatch(setVisibleSignUpModalAC(false));
                                dispatch(setVisibleLoginModalAC(true));
                            }}
                        >
                            Войти
                        </button>
                    </div>
                    <div className="mt-5 text-xs text-textGrey md:text-sm">
                        <p>
                            Нажимая «Получить код», вы принимаете условия
                            <br/>
                            <Link className="underline" to="/">
                                соглашения о конфиденциальности.
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SignUpModal;
