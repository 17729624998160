import React, {useEffect} from "react";

import {Badge, Drawer, Empty, Modal} from "antd";
import ProductInCart from "../../ui/ProductCard/ProductInCart";
import {TfiClose} from "react-icons/tfi";

import {CartIcon, CloseIcon, EmptyBoxIcon} from "../../icons";
import {Link, NavLink, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {clearProductsCartAC, setOpenCartAC} from "../../../store/cartSlice";
import {IProductCart} from "../../../types/productTypes";
import styles from "./CartIcon.module.scss";

export const CartContianer = () => {
    const location = useLocation()
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const {products, totalCountProducts, totalPrice, open} = useAppSelector(
        (state) => state.cartSlice
    );

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        dispatch(clearProductsCartAC());
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setIsModalOpen(false);
        dispatch(setOpenCartAC(false));
    }, [location, dispatch]);

    if (window.innerWidth > 767) {
        return (
            <div>
                <Drawer
                    title={
                        <div>
                            <h2 className="text-3xl">Корзина</h2>
                            <p className="text-gray-400 text-base font-light">
                                Выбрано: {totalCountProducts} товар(а/ов)
                            </p>
                        </div>
                    }
                    placement={"right"}
                    onClose={() => dispatch(setOpenCartAC(false))}
                    open={open}
                    closeIcon={<TfiClose className="w-7 h-7 text-primary hover:text-secondary"/>}
                    destroyOnClose={true}
                    closable={true}
                    width={610}
                    footer={
                        <div className="flex flex-row items-center justify-between py-5 p-5">
                            <div>
                                <h6 className="text-sm font-light text-grey">Итого:</h6>
                                <h5 className="price font-semibold text-[28px] leading-[34px] mt-1">
                                    {(Math.round(totalPrice * 100) / 100).toLocaleString('ru-RU')} с.
                                </h5>
                            </div>
                            <div>
                                {totalCountProducts ? (
                                    <NavLink
                                        to="/order/"
                                        onClick={() => dispatch(setOpenCartAC(false))}
                                        className="hover:bg-secondary hover:text-white duration-300 bg-primary text-white py-4 px-12 text-base font-medium"
                                    >
                                        Оформить заказ
                                    </NavLink>
                                ) : (
                                    <button
                                        disabled
                                        className="hover:bg-secondary hover:text-white duration-300 bg-primary disabled:bg-strokeGrey text-white py-4 px-12 text-base font-medium"
                                    >
                                        Оформить заказ
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                >
                    {isModalOpen && <div className={styles.modal}>
                        <div>
                            <button onClick={() => setIsModalOpen(false)} className={styles.close}><CloseIcon/></button>
                            <h6 className="text-primary font-semibold text-xl md:text-[32px] md:leading-10">
                                Очистить корзину??
                            </h6>
                            <p className="text-primary font-normal mt-2.5 mb-10 text-sm">
                                Вы уверены, что хотите удалить все товары с корзины?
                            </p>
                        </div>
                        <div className="flex flex-row justify-between items-end gap-2">
                            <button
                                onClick={handleCancel}
                                className="hover:bg-secondary hover:text-white duration-300 bg-strokeGrey text-primary py-4 px-7 text-base font-medium w-1/2"
                            >
                                Нет, оставить
                            </button>
                            <button
                                onClick={handleOk}
                                className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium w-1/2"
                            >
                                Удалить
                            </button>
                        </div>
                    </div>}

                    {products.length ? (
                        <div>
                            <div>
                                {products.map((item: IProductCart) => (
                                    <ProductInCart key={item.id} close={() => setIsModalOpen(false)} {...item} />
                                ))}
                                <div className="flex justify-end mt-5">
                                    <button
                                        onClick={showModal}
                                        className="font-normal hover:text-[#E2A3CD] text-xs text-[#808080] underline"
                                    >
                                        Очистить корзину
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{maxWidth: "256px"}}>
                            <p className={styles.text}>Пока тут пусто...</p>
                            <p className={styles.links_text}>
                                Предлагаем перейти в раздел
                                <Link onClick={() => dispatch(setOpenCartAC(false))} to="/products">
                                    новинки
                                </Link>
                                или
                                <Link onClick={() => dispatch(setOpenCartAC(false))} to="products/?sortByValue=a">
                                    хиты
                                </Link>
                            </p>
                        </div>
                    )}
                </Drawer>
                {/* Корзина Иконка на своем месте */}
                <button title="Корзина" onClick={() => dispatch(setOpenCartAC(true))}>
                    <Badge count={totalCountProducts} overflowCount={99}>
                        <CartIcon color="#070707"/>
                    </Badge>
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <Drawer
                    title={
                        <div>
                            <h2 className="text-xl">Корзина</h2>
                            <p className="text-gray-400 text-xs font-light">
                                Выбрано: {totalCountProducts} товар(а/ов)
                            </p>
                        </div>
                    }
                    placement={"bottom"}
                    onClose={() => dispatch(setOpenCartAC(false))}
                    open={open}
                    closeIcon={<TfiClose className="w-7 h-7 text-primary hover:text-secondary"/>}
                    destroyOnClose={true}
                    closable={true}
                    height={"90%"}
                    footer={
                        <div className="flex flex-row items-center justify-between text-base py-5">
                            <div>
                                <h6 className="text-xs md:text-sm font-light text-grey">Итого:</h6>
                                <h5 className="price font-semibold text-2xl leading-7 md:text-[28px] md:leading-[34px] mt-1">
                                    {(Math.ceil(totalPrice * 100) / 100).toLocaleString("ru-RU")} с.
                                </h5>
                            </div>
                            <div>
                                {totalCountProducts ? (
                                    <NavLink
                                        to="/order/"
                                        onClick={() => dispatch(setOpenCartAC(false))}
                                        className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium"
                                    >
                                        Оформить заказ
                                    </NavLink>
                                ) : (
                                    <button
                                        disabled
                                        className="hover:bg-secondary duration-300 bg-primary disabled:bg-strokeGrey text-white py-4 px-7 text-base font-medium"
                                    >
                                        Оформить заказ
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                >
                    {isModalOpen && <div className={styles.modal}>
                        <div className="p-5">
                            <button onClick={() => setIsModalOpen(false)} className={styles.close}><CloseIcon/></button>
                            <h6 className="text-primary font-semibold text-xl md:text-[32px] md:leading-10">
                                Очистить корзину??
                            </h6>
                            <p className="text-primary font-normal mt-2.5 mb-10 text-sm">
                                Вы уверены, что хотите удалить все товары с корзины?
                            </p>
                        </div>
                        <div className="flex flex-row justify-between items-end gap-2">
                            <button
                                onClick={handleCancel}
                                className="hover:bg-secondary hover:text-white duration-300 bg-strokeGrey text-primary py-4 px-7 text-base font-medium w-1/2"
                            >
                                Нет, оставить
                            </button>
                            <button
                                onClick={handleOk}
                                className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium w-1/2"
                            >
                                Удалить
                            </button>
                        </div>
                    </div>}

                    {products.length ? (
                        <div className="cart__products--container">
                            <div className="cart__products--list">
                                {products.map((item: IProductCart) => (
                                    <ProductInCart key={item.id} {...item} />
                                ))}
                                <div className="flex justify-end mt-5">
                                    <button
                                        onClick={showModal}
                                        className="font-normal hover:text-[#E2A3CD] text-xs text-[#808080] underline"
                                    >
                                        Очистить корзину
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="flex flex-col justify-center items-start">
                                <p className={styles.text}>Пока тут пусто...</p>
                                <p className={styles.links_text}>
                                    Предлагаем перейти в раздел{" "}
                                    <Link
                                        onClick={() => dispatch(setOpenCartAC(false))}
                                        to="/products"
                                    >
                                        новинки
                                    </Link>{" "}
                                    или{" "}
                                    <Link
                                        onClick={() => dispatch(setOpenCartAC(false))}
                                        to="products/?sortByValue=a"
                                    >
                                        хиты
                                    </Link>
                                </p>
                            </div>
                        </div>
                            )}
                        </Drawer>
                    {/* Корзина Иконка */}
                        <button
                        title="Корзина"
                        onClick={() => dispatch(setOpenCartAC(true))}
                >
                    <Badge count={totalCountProducts} overflowCount={99}>
                        <CartIcon color="#070707"/>
                    </Badge>
                </button>
            </div>
    );
    }
    };

