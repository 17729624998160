import styles from "./CatalogItemsLow.module.scss"

export function CatalogItemsLow({name, onClick, mobile}: {
    name: string,
    onClick: () => void,
    mobile?: boolean
}) {

    return (
        <div className={`${mobile ? styles.item_mobile : styles.item}`} onClick={onClick}>{name}</div>
    )
}