import React from "react";
import styles from "./IncDecBtn.module.scss"

interface IncDecBtnProps {
    count: number;
    onInc: () => void;
    onDec: () => void;
    amount: number
}

const IncDecBtn: React.FC<IncDecBtnProps> = ({count, onDec, onInc, amount}) => {
    return (
        <div className={styles.container}>
            <button
                className={`${styles.button} ${count <= 1 ? styles.inactive_button : ''}`}
                disabled={count <= 1 ? true : false}
                onClick={() => {
                    onDec();
                }}
            >
                -
            </button>
            <span className="text-base font-semibold text-black px-[10px]">
        {count}
      </span>
            <button
                disabled={count >= amount ? true : false}
                onClick={() => onInc()}
                className={`${styles.button} ${count >= amount ? styles.inactive_button : ''}`}>
                +
            </button>
        </div>
    );
};

export default IncDecBtn;
