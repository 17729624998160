import React from "react";

const EyeIcon: React.FC = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
      >
        <path
            d="M18.3332 9.99999C17.4898 11.4643 16.2755 12.6805 14.8126 13.5262C13.3496 14.3718 11.6896 14.8171 9.99984 14.8171C8.31004 14.8171 6.65008 14.3718 5.18712 13.5262C3.72416 12.6805 2.50986 11.4643 1.6665 9.99999C2.50986 8.5357 3.72416 7.31948 5.18712 6.47381C6.65008 5.62815 8.31004 5.18289 9.99984 5.18289C11.6896 5.18289 13.3496 5.62815 14.8126 6.47381C16.2755 7.31948 17.4898 8.5357 18.3332 9.99999Z"
            stroke="#070707"
            strokeWidth="0.8"
            strokeMiterlimit="10"
        />
        <path
            d="M18.3332 9.99999C17.4898 11.4643 16.2755 12.6805 14.8126 13.5262C13.3496 14.3718 11.6896 14.8171 9.99984 14.8171C8.31004 14.8171 6.65008 14.3718 5.18712 13.5262C3.72416 12.6805 2.50986 11.4643 1.6665 9.99999C2.50986 8.5357 3.72416 7.31948 5.18712 6.47381C6.65008 5.62815 8.31004 5.18289 9.99984 5.18289C11.6896 5.18289 13.3496 5.62815 14.8126 6.47381C16.2755 7.31948 17.4898 8.5357 18.3332 9.99999Z"
            stroke="#070707"
            strokeWidth="0.8"
            strokeMiterlimit="10"
        />
        <path
            d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
            stroke="#070707"
            strokeWidth="0.8"
            strokeMiterlimit="10"
        />
      </svg>
  );
};

export default EyeIcon;
