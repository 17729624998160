import React from "react";
import { IconProps } from "../../types/appTypes";

const ArrowRightIcon: React.FC<IconProps> = ({
                                                 className,
                                                 color,
                                                 height,
                                                 width,
                                             }: IconProps): JSX.Element => {
    return (
        <svg
            width={width ? width : "30"}
            height={height ? height : "30"}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M26.875 15L0.625 15"
                stroke={color ? color : "#070707"}
                strokeWidth="1.2"
                strokeMiterlimit="10"
            />
            <path
                d="M17.325 24.5498L26.875 14.9998L17.325 5.44981"
                stroke={color ? color : "#070707"}
                strokeWidth="1.2"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default ArrowRightIcon;
