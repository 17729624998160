import React from "react";
import {FavoriteIcon, ProfileIcon} from "../../../icons";
import {Badge, Modal} from "antd";
import {CartContianer} from "../../../containerComponents/CartContianer/CartContianer";
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import SignUpModal from "../../../modals/SignUpModal/SignUpModal";
import {
    setVisibleCheckCodeModalAC,
    setVisibleCheckCodeModalResetPassAC,
    setVisibleLoginModalAC,
    setVisibleModalCodeResetPassAC,
    setVisibleModalPhoneAC,
    setVisibleModalResetPassAC,
    setVisibleSignUpModalAC,
} from "../../../../store/userSlice";
import LoginModal from "../../../modals/LoginModal/LoginModal";
import ConfirmWithCode from "../../../modals/ConfirmWithCode/ConfirmWithCode";
import {
    checkPhoneCodeForApprove,
    checkPhoneForResetPassword,
    registerApi,
    resetPassword
} from "../../../../api/userApi";
import Input from "../../../forms/Input/Input";
import SubmitBtn from "../../../forms/btns/SubmitBtn/SubmitBtn";
import SubmitBtnSecondery from "../../../forms/btns/SubmitBtn/SubmitBtnSecondary";
import styles from "./HeaderRightNav.module.scss"


const HeaderRightNav = () => {
    const {products} = useAppSelector((state) => state.favoritesSlice);
    const {
        authorized,
        visibleSignUpModal,
        visibleLoginModal,
        visibleCheckCodeModal,
        birthday,
        phoneNum,
        enterPass,
        userName,
        userSurname,
        visibleModalPhone,
        visibleModalCodeResetPass,
        resetPasswordLoading,
        checkPhoneRes
    } = useAppSelector((state) => state.userSlice);
    const dispatch = useAppDispatch();
    const [phone, setPhone] = React.useState({phone: "", error: false});

    return (
        <div className="headerRightNav">
            <nav className="nav">
                <ul className="headerRightNav-menu flex flex-row items-center">
                    <li className="headerRightNav-list_item hidden md:block mr-7 md:mr-5 lg:mr-10 h-6 w-6">
                        {authorized ?
                            <NavLink
                                title="Избранные"
                                to={"/favorites"}
                                className={({isActive}) =>
                                    isActive ? "active__fav_link" : "fav_link headerRightNav-link"
                                }
                            >
                                <Badge count={products.length} overflowCount={99}>
                                    <FavoriteIcon/>
                                </Badge>
                            </NavLink> :
                            <button title="Избранные" onClick={() => dispatch(setVisibleLoginModalAC(true))}
                                    className="headerRightNav-link">
                                <FavoriteIcon/>
                            </button>
                        }
                    </li>
                    <li className="headerRightNav-link h-6 w-6">
                        <CartContianer/>
                    </li>
                    <li className="headerRightNav-list_item hidden md:block ml-11 md:ml-5 lg:ml-11 h-6 w-6">
                        {authorized ? (
                            <NavLink
                                title="Профиль"
                                to={"/profile/me"}
                                className={({isActive}) =>
                                    isActive ? "active__fav_link" : "fav_link headerRightNav-link"
                                }
                            >
                                <ProfileIcon/>
                            </NavLink>
                        ) : (
                            <button title="Войти" onClick={() => dispatch(setVisibleLoginModalAC(true))}
                                    className="headerRightNav-link">
                                <ProfileIcon/>
                            </button>
                        )}
                    </li>
                </ul>
            </nav>

            <SignUpModal
                open={visibleSignUpModal}
                setOpen={(v) => dispatch(setVisibleSignUpModalAC(v))}
            />
            <LoginModal
                open={visibleLoginModal}
                setOpen={(v) => dispatch(setVisibleLoginModalAC(v))}
            />

            <Modal
                footer={
                    <div className="pr-10 pl-10 pb-10 flex flex-row justify-between my-7">
                        <SubmitBtn
                            className=""
                            onClick={() => {
                                if (phone.phone.length < 9) {
                                    setPhone(prevState => ({...prevState, error: true}))
                                } else {
                                    dispatch(checkPhoneForResetPassword(phone));
                                    // dispatch(resetPassword(phone));
                                    setPhone({phone: "", error: false});
                                }

                            }}
                            txt="Подтвердить"
                        />

                        <SubmitBtnSecondery
                            className=""
                            onClick={() => {
                                setPhone({phone: "", error: false});
                                dispatch(setVisibleModalPhoneAC(false))
                            }}
                            txt="Отмена"
                        />
                    </div>
                }
                open={visibleModalPhone}
                destroyOnClose
                onCancel={() => dispatch(setVisibleModalPhoneAC(false))}
            >
                <div className="pr-10 pl-10 pt-10">
                    <h5 className="text-2xl font-medium mt-5">На номер вышлем пароль</h5>
                    <div className="my-2.5">
                        <Input
                            inpId=""
                            inpName=""
                            onChange={(v) => {
                                setPhone({phone: v, error: false});
                            }}
                            value={phone.phone}
                            defaultValue=""
                            disabled={false}
                            inpClassName={phone.error ? styles.error : ""}
                            inpType="tel"
                            labelTxt="Номер телефона"
                        />
                    </div>
                </div>
            </Modal>

            <ConfirmWithCode
                title="Подтвердите телефон"
                description="Отправили Вам код для смены пароля. Пожалуйста, введите его ниже"
                loading={resetPasswordLoading}
                onClick={(code) => {
                    dispatch(checkPhoneCodeForApprove({code, phone: checkPhoneRes}));
                }
                }
                open={visibleModalCodeResetPass}
                setOpen={(v) => dispatch(setVisibleModalCodeResetPassAC(v))}
            />


            {/* <Modal
      footer={
        <div className="flex flex-row justify-between my-7">
          <SubmitBtn
            className=""
            onClick={() => {
              dispatch(resetPassword(""));
              setNewPassword("")
            }}
            txt="Подтвердить"
          />

          <SubmitBtnSecondery
            className=""
            onClick={() => {
              setNewPassword("")
            }}
            txt="Отмена"
          />
        </div>
      }
      destroyOnClose 
      open={visibleModalResetPass}

        onCancel={()=>{
            dispatch(setVisibleModalResetPassAC(false))
        }}
      >
          <div className="my-2.5">
              <Input
                inpId=""
                inpName=""

                onChange={(v) => {setNewPassword(v)}}
                value={newPassword}
                defaultValue=""
                disabled={false}
                inpClassName=""
                inpType="text"
                labelTxt="Новый пароль"
              />
          </div>
      </Modal> */}

            <ConfirmWithCode
                onClick={(code) => {
                    const obj = {
                        code,
                        phone: checkPhoneRes.id,
                        password: enterPass,
                        firstname: userName.split(' ')[0] ?? "",
                        lastname: userName.split(' ')[1] ?? "",
                        birthdate: birthday
                    }
                    dispatch(
                        registerApi({
                            code,
                            phone: checkPhoneRes.id,
                            password: enterPass,
                            username: userName,
                            first_name: userName.split(' ')[0] ?? '',
                            last_name: userName.split(' ')[1] ?? '',
                            birthdate: birthday
                        })
                    )
                }}
                open={visibleCheckCodeModal}
                setOpen={(v) => dispatch(setVisibleCheckCodeModalAC(v))}
            />
        </div>
    );
};

export default HeaderRightNav;
