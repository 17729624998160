import React from "react";

interface SubmitBtnSeconderyProps {
  txt: string;
  onClick: (...arg: any) => any;
  disabled?: boolean;
  className: string;
}

const SubmitBtnSecondery: React.FC<SubmitBtnSeconderyProps> = ({
  className,
  onClick,
  txt,
  disabled,
}) => {
  return (
    <div>
      <button
        className={`submit_btn_secondary hover:text-white duration-300 bg-strokeGrey text-primary py-[9px] px-7 text-base font-medium h-[50px] ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {txt}
      </button>
    </div>
  );
};

export default SubmitBtnSecondery;
