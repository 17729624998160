import React from "react";
import { IconProps } from "../../types/appTypes";

const ArrowLeftIcon: React.FC<IconProps> = ({
                                              className,
                                              color,
                                              height,
                                              width,
                                            }: IconProps): JSX.Element => {
  return (
      <svg
          width={width ? width : "30"}
          height={height ? height : "30"}
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
      >
        <path
            d="M3.125 15L29.375 15"
            stroke={color ? color : "#070707"}
            strokeWidth="1.2"
            strokeMiterlimit="10"
        />
        <path
            d="M12.675 5.4502L3.125 15.0002L12.675 24.5502"
            stroke={color ? color : "#070707"}
            strokeWidth="1.2"
            strokeMiterlimit="10"
        />
      </svg>
  );
};

export default ArrowLeftIcon;

