import {Modal} from "antd";
import React, {useState} from "react";
import Input from "../../forms/Input/Input";
import SubmitBtn from "../../forms/btns/SubmitBtn/SubmitBtn";
import {Link} from "react-router-dom";
import {ModalsType} from "../../../types/appTypes";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    changeEnterPassAC,
    changePhonNumAC, setVisibleCheckCodeModalAC,
    setVisibleLoginModalAC, setVisibleModalCodeResetPassAC,
    setVisibleModalPhoneAC,
    setVisibleSignUpModalAC,
} from "../../../store/userSlice";
import {checkPhoneForApprove, checkPhoneForRegister, loginApi} from "../../../api/userApi";
import {TfiClose} from "react-icons/tfi";
import styles from "./LoginModal.module.scss"

const LoginModal: React.FC<ModalsType> = ({open, setOpen}): JSX.Element => {
    const dispatch = useAppDispatch();
    const {phoneNum, enterPass, fetchingUserData} = useAppSelector((state) => state.userSlice);
    const [visiblePass, setVisiblePass] = React.useState<boolean>(false);
    const [errors, setErrors] = useState({phone: false, password: false})
    const validate = () => {
        if (phoneNum.length < 9) {
            setErrors(prevState => ({...prevState, phone: true}))
        } else {
            setErrors(prevState => ({...prevState, phone: false}))
        }
        if (!enterPass) {
            setErrors(prevState => ({...prevState, password: true}))
        } else {
            setErrors(prevState => ({...prevState, password: false}))
        }
        if(phoneNum.length === 9 && enterPass) {
            dispatch(loginApi({phone: phoneNum, password: enterPass}))
        }
    }
    const validatePhone = () => {
        if (phoneNum.length < 9) {
            setErrors(prevState => ({...prevState, phone: true}))
        } else {
            setErrors(prevState => ({...prevState, phone: false}))
        }
        if(phoneNum.length === 9) {
            //dispatch(loginApi({phone: phoneNum, password: enterPass}))
            dispatch(checkPhoneForApprove({phone: phoneNum, is_send_sms: true}))
        }
    }
    return (
        <Modal
            footer={null}
            open={open}
            onCancel={() => setOpen(false)}
            destroyOnClose={true}
            closeIcon={<TfiClose className="w-5 h-5"/>}
        >
            <div className="pl-10 pr-10 pt-[50px] py-10 pb-10">
                <h4 className="mb-5 text-xl font-semibold md:text-[32px] text-primary">
                    Войти
                </h4>
                <div>
                    <div className="my-2.5">
                        <Input
                            rootClassName={errors.phone ? styles.error : ""}
                            // inpClassName={errors.phone ? styles.error : ""}
                            inpId=""
                            inpName=""
                            onChange={(v) => {
                                setErrors(prevState => ({...prevState, phone: false}))
                                dispatch(changePhonNumAC(v))
                            }}
                            value={phoneNum}
                            defaultValue=""
                            disabled={false}
                            inpType="tel"
                            labelTxt="Номер телефона"
                        />
                    </div>
                    <div className="my-2.5">
                        <Input
                            rootClassName={errors.password ? styles.error : ""}
                            // inpClassName={errors.password ? styles.error : ""}
                            inpId=""
                            inpName=""
                            onChange={(v) => {
                                setErrors(prevState => ({...prevState, password: false}))
                                dispatch(changeEnterPassAC(v))
                            }}
                            value={enterPass}
                            defaultValue=""
                            disabled={false}
                            inpType="password"
                            labelTxt="Пароль"
                        />
                        <button className="underline text-xs text-lightGrey"
                                onClick={() => {
                                    validatePhone()
                                }}
                        >забыл пароль?
                        </button>
                    </div>
                    <div className="mt-10 mb-[30px]">
                        <SubmitBtn
                            className=""
                            onClick={() => {
                               validate()
                            }
                            }
                            txt="Войти"
                            loading={fetchingUserData}
                        />
                    </div>
                </div>
                <div>
                    <div className="text-sm mb-5">
                        <button
                            className="text-secondary underline"
                            onClick={() => {
                                dispatch(setVisibleLoginModalAC(false));
                                dispatch(setVisibleSignUpModalAC(true));
                            }}
                        >
                            Зарегистрируйтесь,
                        </button>
                        чтобы получать дополнительные скидки, добавлять любимые товары в
                        избранное и быть в курсе самых последних бьюти-новостей.
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default LoginModal;