import React from "react";
import { IconProps } from "../../types/appTypes";

const CloseIcon: React.FC<IconProps> = ({
                                          className,
                                          color,
                                          height,
                                          width,
                                        }: IconProps): JSX.Element => {
  return (
      <svg
          width={width ? width : "25"}
          height={height ? height : "25"}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
      >
        <path
            d="M2 2L23 23"
            stroke={color || "#070707"}
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
        <path
            d="M2 23L23 2"
            stroke={color || "#070707"}
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
      </svg>
  );
};

export default CloseIcon;
