export function createNestedStructure(data: any): any {
    const map: any = {}; // Map to store references to objects by their IDs
    const result: any = []; // Array to store the final nested structure
    if (data) {

        // Create references to objects by their IDs in the map
        data.forEach((obj: any) => {
            obj.children = []; // Add an empty "children" array to each object
            map[obj.id] = obj;
        });

        // Iterate over the objects and build the nested structure
        data.forEach((obj: any) => {
            if (obj.parent_id === null) {
                // If an object has no parent, it's a top-level item
                result.push(obj);
            } else {
                // If an object has a parent, add it to the parent's "children" array
                const parent = map[obj.parent_id];
                parent.children.push(obj);
            }
        });

        return result;
    } else {
        return null;
    }
}