import React from "react";
import { IconProps } from "../../types/appTypes";

const BurgerIcon: React.FC<IconProps> = ({
                                             className,
                                             color,
                                             height,
                                             width,
                                         }: IconProps): JSX.Element => {
    return (
        <svg
            width={width ? width : "25"}
            height={height ? height : "26"} // Обновлено на 26
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.520996 3.02051H24.4793"
                stroke={color ? color : "white"} // Цвет можно менять через пропс color
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M0.520996 13H24.4793"
                stroke={color ? color : "white"} // Цвет можно менять через пропс color
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M0.520996 22.9795H24.4793"
                stroke={color ? color : "white"} // Цвет можно менять через пропс color
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default BurgerIcon;
