import React from "react";
import { IconProps } from "../../types/appTypes";

const FavoriteIcon: React.FC<IconProps> = ({
                                             className,
                                             color,
                                             height,
                                             width,
                                           }: IconProps): JSX.Element => {
  return (
      <svg
          width={width ? width : "25"}
          height={height ? height : "22"}
          viewBox="0 0 25 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
      >
        <path
            d="M3.55186 11.5417L3.20811 11.1979C2.68351 10.6754 2.26697 10.0546 1.98227 9.37106C1.69757 8.68753 1.55029 7.95462 1.54884 7.21417C1.54591 5.71876 2.13715 4.28344 3.19249 3.22395C4.24783 2.16447 5.68082 1.56761 7.17623 1.56468C8.67164 1.56175 10.107 2.15299 11.1664 3.20833L12.4998 4.56249L13.8331 3.20833C14.3508 2.66727 14.9713 2.23503 15.6582 1.93694C16.3452 1.63885 17.0847 1.48092 17.8335 1.47241C18.5823 1.4639 19.3252 1.60498 20.0187 1.88738C20.7123 2.16978 21.3424 2.58781 21.8723 3.11697C22.4021 3.64612 22.821 4.27574 23.1043 4.9689C23.3876 5.66206 23.5297 6.40481 23.5221 7.1536C23.5146 7.90239 23.3576 8.64213 23.0604 9.32946C22.7633 10.0168 22.3318 10.6379 21.7914 11.1562L21.4477 11.5L12.4998 20.4687L6.3227 14.2917L3.55186 11.5417Z"
            stroke={color ? color : "#070707"}
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
      </svg>
  );
};

export default FavoriteIcon;