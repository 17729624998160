export function BasketIcon({color}: { color?: string }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0666 18.75H5.9333C5.52742 18.7505 5.13683 18.5952 4.84218 18.3161C4.54753 18.0369 4.37133 17.6553 4.34997 17.25L3.6333 4.43332H16.3666L15.65 17.25C15.6286 17.6553 15.4524 18.0369 15.1578 18.3161C14.8631 18.5952 14.4725 18.7505 14.0666 18.75Z"
                stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
            <path d="M2.0415 4.43332H17.9582" stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
            <path
                d="M8.40856 1.25H11.5919C12.014 1.25 12.4189 1.41769 12.7174 1.71619C13.0159 2.01468 13.1836 2.41953 13.1836 2.84167V4.43333H6.81689V2.84167C6.81689 2.41953 6.98459 2.01468 7.28308 1.71619C7.58158 1.41769 7.98642 1.25 8.40856 1.25Z"
                stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
            <path d="M10 6.81668V16.3667" stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
            <path d="M13.1831 6.81668V16.3667" stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
            <path d="M6.81689 6.81668V16.3667" stroke={color ? color : "#070707"} stroke-width="0.8" stroke-miterlimit="10"/>
        </svg>
    )
}