import React from "react";
import { IconProps } from "../../types/appTypes";

const Logo: React.FC<IconProps> = ({
                                     className,
                                     color,
                                     height,
                                     width,
                                   }: IconProps): JSX.Element => {
  return (
      <svg
          width={width ? width : "35"}
          height={height ? height : "35"}
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
      >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.739 21.0907L30.7372 21.0874V21.0975L30.739 21.0907ZM30.739 21.0907L32.8134 24.6833C33.9725 22.2104 34.5174 19.4943 34.402 16.7658C34.2865 14.0372 33.5141 11.3769 32.1504 9.01073C30.7866 6.64461 28.8719 4.64266 26.5688 3.175C24.2657 1.70734 21.6423 0.817341 18.9216 0.580627L20.9928 4.18148C22.7474 4.64557 24.3927 5.45251 25.8338 6.55576C27.2749 7.659 28.4833 9.03669 29.3892 10.6094C30.2952 12.182 30.8807 13.9185 31.112 15.7186C31.3431 17.5164 31.2163 19.3421 30.739 21.0907Z"
            fill={color ? color : "black"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1275 17.2509C24.7754 17.3634 25.3544 17.723 25.7423 18.254C26.1076 18.7656 26.2992 19.3808 26.289 20.0093C26.3205 20.5039 26.2336 20.9989 26.0356 21.4533C25.8377 21.9077 25.5343 22.3084 25.1506 22.6221C24.2065 23.2692 23.0731 23.5817 21.9309 23.5098H16.013V22.0655L17.2969 21.8147V18.5148H12.4021V21.8147L13.686 22.0655V23.5098H8.71603V22.0655L9.99488 21.8147V13.1937L8.71603 12.943V11.4936H13.691V12.958L12.4021 13.2088V16.619H17.2919V13.1536L16.008 12.9028V11.4535H21.3591C22.517 11.3875 23.6676 11.6743 24.6591 12.276C25.0513 12.5516 25.3665 12.9231 25.5745 13.355C25.7826 13.7869 25.8766 14.2649 25.8477 14.7434C25.8597 15.2785 25.7055 15.8041 25.4063 16.2479C25.0947 16.7071 24.6477 17.0577 24.1275 17.2509ZM22.9188 13.7454C22.4553 13.4486 21.9082 13.3096 21.3591 13.3492H19.7092V16.5288H21.4143C21.9457 16.565 22.4742 16.4258 22.9188 16.1326C23.0893 15.9939 23.2238 15.8162 23.3111 15.6146C23.3984 15.4129 23.4358 15.1933 23.4203 14.9741C23.4406 14.7441 23.4057 14.5127 23.3185 14.299C23.2313 14.0852 23.0942 13.8955 22.9188 13.7454ZM21.9258 21.6492C22.4461 21.6861 22.9627 21.5387 23.3852 21.233C23.5565 21.0807 23.6907 20.8912 23.7777 20.6792C23.8646 20.4671 23.9019 20.238 23.8868 20.0093C23.9185 19.5221 23.7664 19.0407 23.4605 18.6602C23.2801 18.4919 23.0663 18.3634 22.833 18.2831C22.5997 18.2027 22.3522 18.1723 22.1064 18.1938H19.7092V21.6492H21.9258Z"
            fill={color ? color : "black"}
        />
        <path
            d="M31.4196 27.1006C29.8666 29.3576 27.7882 31.2032 25.3633 32.4782C22.9384 33.7532 20.2398 34.4194 17.5001 34.4194C14.7605 34.4194 12.0619 33.7532 9.63698 32.4782C7.21208 31.2032 5.1336 29.3576 3.58067 27.1006H7.71313C8.98825 28.401 10.5099 29.4341 12.1891 30.1393C13.8683 30.8445 15.6713 31.2078 17.4926 31.2078C19.3139 31.2078 21.1169 30.8445 22.7961 30.1393C24.4753 29.4341 25.997 28.401 27.2721 27.1006H31.4196Z"
            fill={color ? color : "black"}
        />
        <path
            d="M3.89101 15.7116C3.65893 17.5106 3.78517 19.3377 4.26248 21.0876L2.18622 24.6935C1.02719 22.2206 0.482248 19.5046 0.597684 16.776C0.713121 14.0474 1.48553 11.3871 2.84927 9.02096C4.21302 6.65484 6.12779 4.6529 8.4309 3.18523C10.734 1.71757 13.3574 0.827573 16.0781 0.590859L14.0068 4.18168C12.2534 4.64584 10.6091 5.45245 9.16874 6.55501C7.72841 7.65758 6.52054 9.03429 5.61473 10.6058C4.70893 12.1773 4.1231 13.9126 3.89101 15.7116Z"
            fill={color ? color : "black"}
        />
      </svg>
  );
};

export default Logo;
