import React from "react";
import {IconProps} from "../../types/appTypes";

const ProfileIcon: React.FC<IconProps> = ({
                                              className,
                                              color,
                                              height,
                                              width,
                                              fill
                                          }: IconProps): JSX.Element => {
    return (
        <svg width={width ? width : "26"}
             height={height ? height : "25"} viewBox="0 0 25 25" fill={fill ? fill : "none"}
             xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clip-path="url(#clip0_413_9552)">
                <path
                    d="M12.5 13.5209C15.7964 13.5209 18.4687 10.8486 18.4687 7.55212C18.4687 4.25567 15.7964 1.58337 12.5 1.58337C9.20355 1.58337 6.53125 4.25567 6.53125 7.55212C6.53125 10.8486 9.20355 13.5209 12.5 13.5209Z"
                    stroke={color ? color : "#070707"} stroke-width="1.5" stroke-miterlimit="10"/>
                <path
                    d="M1.5625 24.4583L1.94792 22.3229C2.40323 19.8567 3.70806 17.6279 5.63574 16.0237C7.56342 14.4196 9.99214 13.5413 12.5 13.5416C15.0108 13.5422 17.4419 14.4234 19.37 16.0318C21.2981 17.6401 22.6011 19.8737 23.0521 22.3437L23.4375 24.4791"
                    stroke={color ? color : "#070707"} stroke-width="1.5" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_413_9552">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default ProfileIcon;
