import React from "react";
import { IconProps } from "../../types/appTypes";

const FavoriteActiveIcon: React.FC<IconProps> = ({
                                                     className,
                                                     color,
                                                     height,
                                                     width,
                                                 }: IconProps): JSX.Element => {
    return (
        <svg
            width={width ? width : "25"}
            height={height ? height : "25"}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.55186 13.5417L3.20811 13.1979C2.68351 12.6754 2.26697 12.0546 1.98227 11.3711C1.69757 10.6875 1.55029 9.95462 1.54884 9.21417C1.54591 7.71876 2.13715 6.28344 3.19249 5.22395C4.24783 4.16447 5.68082 3.56761 7.17623 3.56468C8.67164 3.56175 10.107 4.15299 11.1664 5.20833L12.4998 6.56249L13.8331 5.20833C14.3508 4.66727 14.9713 4.23503 15.6582 3.93694C16.3452 3.63885 17.0847 3.48092 17.8335 3.47241C18.5823 3.4639 19.3252 3.60498 20.0187 3.88738C20.7123 4.16978 21.3424 4.58781 21.8723 5.11697C22.4021 5.64612 22.821 6.27574 23.1043 6.9689C23.3876 7.66206 23.5297 8.40481 23.5221 9.1536C23.5146 9.90239 23.3576 10.6421 23.0604 11.3295C22.7633 12.0168 22.3318 12.6379 21.7914 13.1562L21.4477 13.5L12.4998 22.4687L6.3227 16.2917L3.55186 13.5417Z"
                fill={color ? color : "#F400A1"}
            />
        </svg>
    );
};

export default FavoriteActiveIcon;