import React, {useEffect, useRef} from "react";
import {INavLinkType} from "../../../../types/appTypes";
import {Link, useNavigate} from "react-router-dom";
import "./Nav.scss";
import styles from "./MobileMainNav.module.scss"
import SubmitBtn from "../../../forms/btns/SubmitBtn/SubmitBtn";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {setVisibleLoginModalAC} from "../../../../store/userSlice";
import {BurgerIcon, ProfileIcon} from "../../../icons";
import {setBurgerOpened} from "../../../../store/appSlice";
import {PhoneIcon} from "../../../icons/PhoneIcon";
import {CatalogItemsHightMobile} from "../../CatalogItems/CatalogItemsHight/CatalogItemsHightMobile";
import {categoryType} from "../../../../types/productTypes";
import {CatalogItemsLow} from "../../CatalogItems/CatalogItemsLow/CatalogItemsLow";
import {createNestedStructure} from "../../../../utils/filterCatalog";
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import burgerIcon from "../../../icons/BurgerIcon";
import {current} from "@reduxjs/toolkit";

const mainNavData: INavLinkType[] = [
    {
        id: 0,
        link: "/favorites",
        text: "Избранное",
    },
    {
        id: 1,
        link: "/brands",
        text: "Бренды",
    },
    // {
    //     id: 2,
    //     link: "/gifts",
    //     text: "Подарочные карты",
    // },
    {
        id: 3,
        link: "/promotion",
        text: "Акции",
    },
    {
        id: 4,
        link: "/markets",
        text: "Магазины",
    },
];

const MobileMainNav: React.FC = (): JSX.Element => {
        const dispatch = useAppDispatch();
        const {authorized} = useAppSelector((state) => state.userSlice);
        const {burgerOpened} = useAppSelector((state) => state.appSlice);
        const navigate = useNavigate()
        const burgerRef = useRef<HTMLDivElement | null>(null);
        const burgerIconRef = useRef<HTMLButtonElement | null>(null);
        const {data: categories, isLoading: isFetchingCategories} = useQuery({
            queryKey: ["categories"],
            queryFn: async () => {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/categories`,
                    {params: {}}
                );
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                return response.data;
            },
        });
        const myOnClick = (value: string) => {
            navigate(`/products/bycategory/${value}`);
            dispatch(setBurgerOpened(false));
        };

        function renderMyMenuItems(
            items
                :
                categoryType[] | null
        ):
            JSX.Element[] | null {
            if (items) {
                return items.map((item: any) => {
                    if (item.children?.length > 0) {
                        return (
                            <CatalogItemsHightMobile
                                // @ts-ignore
                                key={item.id}
                                name={item.name}
                                onClick={() => myOnClick(item.id)}
                            >
                                {renderMyMenuItems(item.children)}
                            </CatalogItemsHightMobile>
                        );
                    } else {
                        return <CatalogItemsLow mobile={true} name={item.name} onClick={() => myOnClick(item.id)}/>
                    }
                });
            } else {
                return null;
            }
        }

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if  (
                    burgerRef.current &&
                    !burgerRef.current.contains(event.target as Node) &&
                    burgerIconRef.current &&
                    !burgerIconRef.current.contains(event.target as Node)
                ) {
                    dispatch(setBurgerOpened(false)); // Закрыть бургер, если клик был вне его
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [dispatch]);

        return (
            <div className="flex justify-center md:mt-5 h-[25px]">
                <button
                    ref={burgerIconRef}
                    className={`burger relative block md:hidden h-[25px] w-[25px] ${
                        burgerOpened ? "burger__opened" : ""
                    } `}
                    onClick={() => {
                        dispatch(setBurgerOpened(!burgerOpened));
                    }}
                >
                    <BurgerIcon color="black"/>
                </button>
                {burgerOpened && <nav
                    ref={burgerRef}
                    className={`${styles.nav} ${
                        burgerOpened ? "visible burger__nav--opened" : "invisible"
                    }`}
                >
                    <ul className="main__nav-menu flex flex-col md:flex-row h-[75vh]">
                        <li className="main__nav-list_item main__nav-link text-base font-medium text-primary duration-300">
                            {/*<CatalogBtn />*/}
                            <CatalogItemsHightMobile className={styles.items} name="Каталог">
                                {renderMyMenuItems(createNestedStructure(categories))}
                            </CatalogItemsHightMobile>
                        </li>
                        {mainNavData.map((elem: INavLinkType) => (
                            <li key={elem.id} className="main__nav-list_item py-1.5">
                                <Link
                                    to={elem.link}
                                    className={styles.items}
                                    onClick={() => {
                                        dispatch(setBurgerOpened(false));
                                    }}
                                >
                                    {elem.text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {authorized ? (
                        <div>
                            <Link onClick={() => {
                                dispatch(setBurgerOpened(false));
                            }} className={styles.bottom_buttons} to="/profile/me"><ProfileIcon/>Личный кабинет</Link>
                            <div className={styles.bottom_buttons}><PhoneIcon/>(+992) 940909009</div>
                        </div>
                    ) : (
                        <SubmitBtn
                            className="w-full font-medium text-base"
                            onClick={() => {
                                dispatch(setVisibleLoginModalAC(true));
                            }}
                            txt="Войти или зарегистрироваться"
                            tooltipTitle="Войти или зарегистрироваться"
                            disabled={false}
                            secondary={false}
                        />
                    )}
                </nav>}
            </div>
        );
    }
;

export default MobileMainNav;
