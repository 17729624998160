import React, {useState} from "react";
import { ModalsType } from "../../../types/appTypes";
import { Modal } from "antd";
import SubmitBtn from "../../forms/btns/SubmitBtn/SubmitBtn";
import { TfiClose } from "react-icons/tfi";
import {inspect} from "util";
import styles  from "./ConfirmWithCode.module.scss"

interface confirmWithCodeProps extends ModalsType {
  onClick: (code: string) => void;
  loading?: boolean;
  title?: string;
  description?: string;
}

const ConfirmWithCode: React.FC<confirmWithCodeProps> = ({
                                                           open,
                                                           setOpen,
                                                           onClick,
                                                           loading,
                                                           description,
                                                           title,
                                                         }): JSX.Element => {
  const [code, setCode] = React.useState<string[]>(Array(4).fill(""));
  const inputRefs = React.useRef<HTMLInputElement[]>([]);
  const [codeError, setCodeError] = useState(false)

  const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number
  ) => {
    const { value } = event.target;
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value.length === 1 && index < code.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
      index: number
  ) => {
    if (event.key === "Backspace" && code[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  React.useEffect(() => {
    if (inputRefs.current) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 700);
    }
  }, [inputRefs.current,open]);

  return (
      <Modal
          closeIcon={
            <TfiClose className="w-7 h-7 text-primary hover:text-secondary" />
          }
          footer={null}
          open={open}
          onCancel={() => {
            setOpen(false);
            setCode(Array(4).fill(""));
          }}
          destroyOnClose={true}
      >
        <div className="pl-10 pr-10 pt-[50px] py-10 pb-10">
          <h4 className="text-xl font-semibold md:text-[32px] text-primary">
            {title ? title : "Подтвердить вход"}
          </h4>
          <p className="my-2.5 text-base font-medium text-primary">
            {description
                ? description
                : "Отправили Вам код для входа в аккаунт. Пожалуйста, введите его ниже"}
          </p>
          <div className="my-5 flex flex-row gap-x-5">
            {code.map((digit, index) => (
                <input
                    required
                    key={index}
                    type="number"
                    maxLength={1}
                    value={digit}
                    ref={(ref) =>
                        (inputRefs.current[index] = ref as HTMLInputElement)
                    }
                    onChange={(event) => {
                        setCodeError(false)
                        handleInputChange(event, index)
                    }}
                    onKeyDown={(event) => handleInputKeyDown(event, index)}
                    className={`${codeError ? styles.error : ""} otpInp text-center border-b border-b-primary text-primary w-[45px] h-[45px] md:w-[60px] md:h-[60px]`}
                />
            ))}
          </div>
          <div className="mt-[30px]">
            <SubmitBtn
                loading={loading}
                className=""
                onClick={() => {
                    if(code?.join("").length<4) {
                        setCodeError(true)
                    }else {
                        onClick(code?.join(""));
                        console.log("code")
                        setCode(Array(4).fill(""));
                    }
                }}
                txt="Подтвердить"
                disabled={false}
            />
          </div>
        </div>
      </Modal>
  );
};

export default ConfirmWithCode;