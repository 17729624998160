import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IProductCart, productType } from "../types/productTypes";
import { message } from "antd";
import returnDiscount from "../utils/returnDiscount";

interface CartState {
  totalCountProducts: number;
  totalPrice: number;
  sumPrice: number;
  sumDiscount: number;
  products: IProductCart[] | [];
  open: boolean;
}

const initialState: CartState = {
  totalCountProducts: 0,
  totalPrice: 0,
  sumPrice: 0,
  sumDiscount: 0,
  products: [],
  open: false,
};

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setOpenCartAC(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    addProductToCartAC(state, action: PayloadAction<IProductCart>) {
      if (state.products.length > 0) {
        //@ts-ignore
        if (state.products.find((e) => e.id === action.payload.id)) {
          //@ts-ignore
          state.products = state.products.map((e) => {
            if (e.id === action.payload.id) {
              return { ...e, count: e.count + 1 };
            } else {
              return e;
            }
          });
        } else {
          // @ts-ignore
          state.products.push(action.payload);
        }
        //@ts-ignore
        state.totalCountProducts = state.products.reduce((a, b) => {
          return a + b.count;
        }, 0);
        //@ts-ignore
        const totalPrice = state.products.reduce((a, b) => {
          if (b.actions?.find((e: any) => e)?.discount) {
            const discountedPrice =
              b.price - (b.price * returnDiscount(b.actions)) / 100;
            return a + discountedPrice * b.count;
          } else {
            return a + b.price * b.count;
          }
        }, 0);
        //@ts-ignore
        state.totalPrice = Math.round(totalPrice * 100) / 100;
        // @ts-ignore
        const sumDiscount = state.products.reduce((a: any, b: any) => {
          if (b.actions?.find((e: any) => e)?.discount) {
            const discountedPrice = returnDiscount(b.actions);
            return a + discountedPrice * b.count;
          } else {
            return 0;
          }
        }, 0);
        // @ts-ignore
        state.sumDiscount = Math.round(sumDiscount * 100) / 100;
        // @ts-ignore
        const sumPrice = state.products.reduce((a: any, b: any) => {
          return a + b.price * b.count;
        }, 0);
        // @ts-ignore
        state.sumPrice = Math.round(sumPrice);
      } else {
        //@ts-ignore
        state.products.push(action.payload);
        state.totalCountProducts = action.payload.count;
        // state.totalPrice = action.payload.price;
        state.sumPrice = action.payload.price * action.payload.count;

        let discount: any = returnDiscount(action.payload.actions);
        if (discount) {
          const discountedPrice =
            action.payload.price - (action.payload.price * discount) / 100;
          // state.sumDiscount = discountedPrice;
          state.totalPrice =
            Math.round(discountedPrice * action.payload.count * 100) / 100;
          state.sumDiscount =
            Math.round(discount * action.payload.count * 100) / 100;
        } else {
          state.totalPrice =
            Math.round(action.payload.price * action.payload.count * 100) / 100;
          // state.totalPrice = 0;
          state.sumDiscount = 0;
          // state.sumDiscount = 0;
        }
      }
      // message["open"]({
      //   type: "success",
      //   content: "Товар добавлен в корзину",
      //   className: "message__added_to_cart",
      //   style: {
      //     marginTop: "8vh",
      //   },
      // });
    },
    incremmentProductAC(state, action: PayloadAction<number | string>) {
      state.products.map((e) => {
        if (e.id === action.payload) {
          return e.count++;
        } else {
          return e;
        }
      });
      // @ts-ignore
      const sumPrice = state.products.reduce((a: any, b: any) => {
        return a + b.price * b.count;
      }, 0);
      // @ts-ignore
      state.sumPrice = Math.round(sumPrice);
      //@ts-ignore
      state.totalCountProducts = state.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
      //@ts-ignore
      const totalPrice = state.products.reduce((a, b) => {
        if (b.actions?.find((e: any) => e)?.discount) {
          const discountedPrice =
            b.price - (b.price * returnDiscount(b.actions)) / 100;
          return a + discountedPrice * b.count;
        } else {
          return a + b.price * b.count;
        }
      }, 0);
      //@ts-ignore
      state.totalPrice = Math.round(totalPrice);
      // state.totalPrice = Math.ceil(totalPrice);
    },
    decremmentProductAC(state, action: PayloadAction<number | string>) {
      state.products.map((e) => {
        if (e.id === action.payload) {
          return e.count--;
        } else {
          return e;
        }
      });
      // @ts-ignore
      const sumPrice = state.products.reduce((a: any, b: any) => {
        return a + b.price * b.count;
      }, 0);
      // @ts-ignore
      state.sumPrice = Math.round(sumPrice);
      //@ts-ignore
      state.totalCountProducts = state.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
      //@ts-ignore
      const totalPrice = state.products.reduce((a, b) => {
        if (b.actions?.find((e: any) => e)?.discount) {
          const discountedPrice =
            b.price - (b.price * returnDiscount(b.actions)) / 100;
          return a + discountedPrice * b.count;
        } else {
          return a + b.price * b.count;
        }
      }, 0);
      //@ts-ignore
      state.totalPrice = Math.round(totalPrice * 100) / 100;
    },
    removeProductFromCartAC(state, action: PayloadAction<string | number>) {
      state.products = state.products.filter((e) => e.id != action.payload);
      //@ts-ignore
      state.totalCountProducts = state.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
      // @ts-ignore
      const sumPrice = state.products.reduce((a: any, b: any) => {
        return a + b.price * b.count;
      }, 0);
      // @ts-ignore
      state.sumPrice = Math.round(sumPrice);
      //@ts-ignore
      const totalPrice = state.products.reduce((a, b) => {
        if (b.actions?.find((e: any) => e)?.discount) {
          const discountedPrice =
            b.price -
            // @ts-ignore
            (b.price * returnDiscount(b.actions)) / 100;
          return a + discountedPrice * b.count;
        } else {
          return a + b.price * b.count;
        }
      }, 0);
      //@ts-ignore
      state.totalPrice = Math.round(totalPrice);
    },
    clearProductsCartAC(state) {
      state.products = [];
      state.totalCountProducts = 0;
      state.totalPrice = 0;
      state.sumDiscount = 0;
      state.sumPrice = 0;
    },
    fillCartAC(
      state,
      action: PayloadAction<{
        products: IProductCart[] | [];
        sumDiscount: any;
        sumPrice: any;
        totalCountProducts: any;
        totalPrice: any;
      }>
    ) {
      state.products = [...state.products, ...action.payload.products];
      // state.products = ;
      state.sumDiscount = action.payload.sumDiscount;
      state.sumPrice = action.payload.sumPrice;
      state.totalPrice = action.payload.totalPrice;


      state.totalCountProducts = action.payload.totalCountProducts;
      //@ts-ignore
      state.totalCountProducts = action.payload.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
    },
  },
});

export const {
  addProductToCartAC,
  decremmentProductAC,
  incremmentProductAC,
  removeProductFromCartAC,
  clearProductsCartAC,
  setOpenCartAC,
  fillCartAC,
} = cartSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootStimport { IProduct } from './../types/products';
// ate) => state.counter.value;

export default cartSlice.reducer;
