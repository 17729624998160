import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {userInfoType} from "../types/appTypes";
import {
    changePasswordApi,
    changePhoneNumApi,
    changeUserDataApi,
    checkPhoneCodeForChangeItApi,
    checkPhoneCodeForSetPassword,
    // checkPhoneCodeForResetPassword,
    checkPhoneForChangeIt,
    checkPhoneForChangeUserDataApi,
    checkPhoneForRegister,
    checkPhoneForSetPassword,
    checkPhoneForResetPassword,
    getUserData,
    loginApi,
    registerApi,
    resetPassword, checkPhoneForApprove, checkPhoneCodeForApprove,
} from "../api/userApi";
import {notification} from "antd";
import {date} from "yup";

export interface UserState {
    authorized: boolean;
    userInfo: userInfoType | null;
    refreshtoken: string;
    accesstoken: string;

    fetchingUserData: boolean;
    err: string;

    registerData: any;

    visibleSignUpModal: boolean;
    visibleCheckCodeModal: boolean;
    visibleLoginModal: boolean;
    phoneNum: string;
    userName: string;
    userSurname: string;
    enterPass: string;
    birthday: string;

    checkPhoneRes: any;
    errCheckPhoneRes: string;
    fetchingCheckPhoneRes: boolean;
    visibleModalCurrentPhoneNum: boolean;

    loadingChangePhoneNum: boolean;

    loadingUserData: boolean;

    visibleModalChangeUserData: boolean;
    modalCheckPhoneForChangeUserData: boolean;
    checkPhoneForChangeUserData: any;
    loadingCheckPhoneForChangeUserData: boolean;
    errorCheckPhoneForChangeUserData: boolean;
    errorChangePassword: boolean

    // checkPhoneForChangeUserData:any;
    checkPhoneCodeForChangeIt: boolean;

    visibleModalPhone: boolean;

    visibleModalToChangePassword: boolean;
    visibleCheckCodeModalResetPass: boolean;
    visibleModalResetPass: boolean;

    visibleModalCodeResetPass: boolean;
    checkPhoneForResetPasswordData: any;
    resetPasswordLoading: boolean;

    checkPhoneForChangeItLoading: boolean;
}

const initialState: UserState = {
    accesstoken: "",
    authorized: false,
    err: "",
    fetchingUserData: false,
    refreshtoken: "",
    userInfo: null,

    registerData: null,
    visibleCheckCodeModal: false,
    visibleLoginModal: false,
    visibleSignUpModal: false,

    phoneNum: "",
    userName: "",
    userSurname: "",
    enterPass: "",
    birthday: "",

    checkPhoneRes: null,
    errCheckPhoneRes: "",
    fetchingCheckPhoneRes: false,
    visibleModalCurrentPhoneNum: false,

    loadingChangePhoneNum: false,
    loadingUserData: false,
    visibleModalChangeUserData: false,
    modalCheckPhoneForChangeUserData: false,
    checkPhoneForChangeUserData: null,
    loadingCheckPhoneForChangeUserData: false,
    errorCheckPhoneForChangeUserData: false,
    errorChangePassword: false,

    checkPhoneCodeForChangeIt: false,
    visibleModalPhone: false,

    visibleModalToChangePassword: false,
    visibleCheckCodeModalResetPass: false,
    visibleModalResetPass: false,
    visibleModalCodeResetPass: false,
    checkPhoneForResetPasswordData: null,
    resetPasswordLoading: false,
    checkPhoneForChangeItLoading: false,
};

export const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        logoutAC(state) {
            state.accesstoken = "";
            state.authorized = false;
            state.refreshtoken = "";
            state.userInfo = null;
        },
        setVisibleLoginModalAC(state, action: PayloadAction<boolean>) {
            state.visibleLoginModal = action.payload;
        },
        setVisibleSignUpModalAC(state, action: PayloadAction<boolean>) {
            state.visibleSignUpModal = action.payload;
        },
        setVisibleCheckCodeModalAC(state, action: PayloadAction<boolean>) {
            state.visibleCheckCodeModal = action.payload;
        },
        changePhonNumAC(state, action: PayloadAction<string>) {
            state.phoneNum = action.payload;
        },
        changeUserNameAC(state, action: PayloadAction<string>) {
            state.userName = action.payload;
        },
        changeUserSurnameAC(state, action: PayloadAction<string>) {
            state.userSurname = action.payload;
        },
        changeEnterPassAC(state, action: PayloadAction<string>) {
            state.enterPass = action.payload;
        },
        changeBirthdayAC(state, action: PayloadAction<string>) {
            state.birthday = action.payload;
        },
        visibleModalCurrentPhoneNumAC(state, action: PayloadAction<boolean>) {
            state.visibleModalCurrentPhoneNum = action.payload;
        },
        visibleModalChangeUserDataAC(state, action: PayloadAction<boolean>) {
            state.visibleModalChangeUserData = action.payload;
        },
        modalCheckPhoneForChangeUserDataAC(state, action: PayloadAction<boolean>) {
            state.modalCheckPhoneForChangeUserData = action.payload;
        },
        setTokensAC(state, action) {
            state.accesstoken = action.payload.token;
            state.refreshtoken = action.payload.refreshtoken;
        },
        checkPhoneCodeForChangeItAC(state, action: PayloadAction<boolean>) {
            state.checkPhoneCodeForChangeIt = action.payload;
        },
        setVisibleModalPhoneAC(state, action: PayloadAction<boolean>) {
            state.visibleModalPhone = action.payload;
        },
        setVisibleModalToChangePasswordAC(state, action: PayloadAction<boolean>) {
            state.visibleModalToChangePassword = action.payload;
        },
        setVisibleCheckCodeModalResetPassAC(state, action: PayloadAction<boolean>) {
            state.visibleCheckCodeModalResetPass = action.payload;
        },
        setVisibleModalResetPassAC(state, action: PayloadAction<boolean>) {
            state.visibleModalResetPass = action.payload;
        },
        setVisibleModalCodeResetPassAC(state, action: PayloadAction<boolean>) {
            state.visibleModalCodeResetPass = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(loginApi.pending, (state) => {
            state.err = "";
            state.fetchingUserData = true;
        });
        builder.addCase(loginApi.rejected, (state, action: PayloadAction<any>) => {
            state.err = action.payload;
            state.fetchingUserData = false;
            state.refreshtoken = "";
            state.accesstoken = "";
            state.authorized = false;
            state.userInfo = null;
        });
        builder.addCase(loginApi.fulfilled, (state, action) => {
            state.err = "";
            state.fetchingUserData = false;
            state.authorized = true;
            state.refreshtoken = action.payload.refresh;
            state.accesstoken = action.payload.access;
            state.userInfo = action.payload?.userInfo;
            state.visibleLoginModal = false;
        });
        // registerApi
        builder.addCase(registerApi.pending, (state) => {
            state.err = "";
            state.fetchingUserData = true;
        });
        builder.addCase(
            registerApi.rejected,
            (state, action: PayloadAction<any>) => {
                state.err = action.payload;
                state.authorized = false;
                state.fetchingUserData = false;
                state.refreshtoken = "";
                state.accesstoken = "";
                state.userInfo = null;
                state.visibleCheckCodeModal = false;
                // state.visibleSignUpModal = false;
                console.log(action)
            }
        );
        builder.addCase(
            registerApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.err = "";
                state.fetchingUserData = false;
                state.authorized = true;
                state.refreshtoken = action.payload?.refresh;
                state.accesstoken = action.payload?.access;
                state.userInfo = action.payload?.userInfo;
                state.visibleCheckCodeModal = false;
                state.visibleSignUpModal = false;
                console.log(action)
            }
        );
        // checkPhone
        builder.addCase(checkPhoneForRegister.pending, (state) => {
            state.errCheckPhoneRes = "";
            state.fetchingCheckPhoneRes = true;
        });
        builder.addCase(
            checkPhoneForRegister.rejected,
            (state, action: PayloadAction<any>) => {
                state.errCheckPhoneRes = action.payload;
                state.fetchingCheckPhoneRes = false;
                state.checkPhoneRes = null;
                console.log("rejected")
            }
        );
        builder.addCase(
            checkPhoneForRegister.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.errCheckPhoneRes = "";
                state.fetchingCheckPhoneRes = false;
                state.checkPhoneRes = action.payload;
                state.visibleCheckCodeModal = true;
                state.visibleSignUpModal = false;
                console.log(action.payload)
            }
        );
        builder.addCase(
            changePhoneNumApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.loadingChangePhoneNum = false;
                state.userInfo = action.payload;
                state.visibleModalCurrentPhoneNum = false;
            }
        );
        // checkPhoneForChangeIt
        builder.addCase(checkPhoneForChangeIt.pending, (state) => {
            state.checkPhoneRes = null;
            state.checkPhoneForChangeItLoading = true;
        });
        builder.addCase(
            checkPhoneForChangeIt.rejected,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneRes = null;
                state.checkPhoneForChangeItLoading = false;
            }
        );
        builder.addCase(
            checkPhoneForChangeIt.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneRes = action.payload;
                state.checkPhoneCodeForChangeIt = true;
                state.checkPhoneForChangeItLoading = false;
            }
        );
        builder.addCase(checkPhoneCodeForChangeItApi.pending, (state) => {
        });
        builder.addCase(
            checkPhoneCodeForChangeItApi.rejected,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneCodeForChangeIt = false;
            }
        );
        builder.addCase(
            checkPhoneCodeForChangeItApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneCodeForChangeIt = false;
                state.visibleModalCurrentPhoneNum = true;
            }
        );

        // changeUserDataApi
        builder.addCase(changeUserDataApi.pending, (state) => {
            state.loadingUserData = true;
        });
        builder.addCase(
            changeUserDataApi.rejected,
            (state, action: PayloadAction<any>) => {
                state.loadingUserData = false;
                notification.error({message: action.payload});
            }
        );
        builder.addCase(
            changeUserDataApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.userInfo = action.payload;
                state.loadingUserData = false;
                state.visibleModalChangeUserData = false;
            }
        );
        // checkPhoneForChangeUserData
        builder.addCase(checkPhoneForChangeUserDataApi.pending, (state) => {
            state.loadingCheckPhoneForChangeUserData = true;
            state.errorCheckPhoneForChangeUserData = false;
        });
        builder.addCase(
            checkPhoneForChangeUserDataApi.rejected,
            (state, action: PayloadAction<any>) => {
                state.loadingCheckPhoneForChangeUserData = false;
                state.errorCheckPhoneForChangeUserData = true;
            }
        );
        builder.addCase(
            checkPhoneForChangeUserDataApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneForChangeUserData = action.payload;
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
                state.visibleModalChangeUserData = true;
                state.errorCheckPhoneForChangeUserData = false;
            }
        );

        builder.addCase(resetPassword.pending, (state) => {
            state.resetPasswordLoading = true;
        });
        builder.addCase(
            resetPassword.rejected,
            (state, action: PayloadAction<any>) => {
                state.resetPasswordLoading = false;
                state.visibleModalPhone = false;
                state.visibleCheckCodeModalResetPass = false;
                state.visibleModalCodeResetPass = false;
            }
        );
        builder.addCase(
            resetPassword.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.resetPasswordLoading = false;
                state.visibleModalPhone = false;
                state.visibleCheckCodeModalResetPass = false;
                state.visibleModalCodeResetPass = false;
                notification.success({message: "Мы выслали вам пароль на смс"});
            }
        );

        builder.addCase(checkPhoneForSetPassword.pending, (state) => {
            state.loadingCheckPhoneForChangeUserData = true;
            state.modalCheckPhoneForChangeUserData = false;
        });
        builder.addCase(
            checkPhoneForSetPassword.rejected,
            (state, action: PayloadAction<any>) => {
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
            }
        );
        builder.addCase(
            checkPhoneForSetPassword.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.visibleCheckCodeModalResetPass = true;
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
            }
        );
        builder.addCase(checkPhoneCodeForSetPassword.pending, (state) => {
            state.loadingCheckPhoneForChangeUserData = false;
            state.modalCheckPhoneForChangeUserData = true;
        });
        builder.addCase(
            checkPhoneCodeForSetPassword.rejected,
            (state, action: PayloadAction<any>) => {
                state.visibleCheckCodeModalResetPass = false;
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
            }
        );
        builder.addCase(
            checkPhoneCodeForSetPassword.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.visibleCheckCodeModalResetPass = false;
                state.visibleModalToChangePassword = true;
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
            }
        );

        builder.addCase(getUserData.pending, (state) => {
        });
        builder.addCase(
            getUserData.rejected,
            (state, action: PayloadAction<any>) => {
            }
        );
        builder.addCase(
            getUserData.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.userInfo = action.payload;
            }
        );

        builder.addCase(checkPhoneForResetPassword.pending, (state) => {
            state.visibleModalCodeResetPass = true;
        });
        builder.addCase(
            checkPhoneForResetPassword.rejected,
            (state, action: PayloadAction<any>) => {
            }
        );
        builder.addCase(
            checkPhoneForResetPassword.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneForResetPasswordData = action.payload;
                // state.visibleModalCodeResetPass = true;
            }
        );

        builder.addCase(changePasswordApi.pending, (state) => {
            state.loadingCheckPhoneForChangeUserData = true;
            state.modalCheckPhoneForChangeUserData = false;
        });
        builder.addCase(
            changePasswordApi.rejected,
            (state, action: PayloadAction<any>) => {
                notification.error({message: action.payload});
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
                state.errorChangePassword = true
            }
        );
        builder.addCase(
            changePasswordApi.fulfilled,
            (state, action: PayloadAction<any>) => {
                notification.success({message: "Пароль успешно изменён!"});
                state.visibleModalToChangePassword = false;
                state.loadingCheckPhoneForChangeUserData = false;
                state.modalCheckPhoneForChangeUserData = false;
            }
        );

        builder.addCase(checkPhoneForApprove.pending, (state) => {
            state.checkPhoneRes = null;
            state.checkPhoneForChangeItLoading = false;

        });

        builder.addCase(checkPhoneForApprove.rejected, (state, action: PayloadAction<any>) => {
            state.errCheckPhoneRes = action.payload;
            state.fetchingCheckPhoneRes = false;
            state.checkPhoneRes = null;
            console.log("rejected");
        });

        builder.addCase(checkPhoneForApprove.fulfilled, (state, action: PayloadAction<any>) => {
            state.errCheckPhoneRes = "";
            state.fetchingCheckPhoneRes = false;
            state.checkPhoneRes = action.payload;
            state.visibleLoginModal = false;
            state.visibleModalCodeResetPass = true;
            state.visibleSignUpModal = false;
            console.log(action.payload);
        });

//проверка кода
        builder.addCase(checkPhoneCodeForApprove.pending, (state) => {
            state.checkPhoneRes = null;
            state.checkPhoneForChangeItLoading = true;
        });
        builder.addCase(
            checkPhoneCodeForApprove.rejected,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneRes = null;
                state.checkPhoneForChangeItLoading = false;
            }
        );
        builder.addCase(checkPhoneCodeForApprove.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.checkPhoneRes = action.payload;
                state.checkPhoneCodeForChangeIt = true;
                state.checkPhoneForChangeItLoading = false;
                state.visibleModalCodeResetPass = false
                state.visibleLoginModal = true;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const {
    setVisibleLoginModalAC,
    setVisibleSignUpModalAC,
    logoutAC,
    changePhonNumAC,
    changeUserNameAC,
    changeUserSurnameAC,
    changeEnterPassAC,
    changeBirthdayAC,
    setVisibleCheckCodeModalAC,
    visibleModalCurrentPhoneNumAC,
    visibleModalChangeUserDataAC,
    modalCheckPhoneForChangeUserDataAC,
    setTokensAC,
    setVisibleModalPhoneAC,
    checkPhoneCodeForChangeItAC,
    setVisibleModalToChangePasswordAC,
    setVisibleCheckCodeModalResetPassAC,
    setVisibleModalResetPassAC,
    setVisibleModalCodeResetPassAC,
} = userSlice.actions;

export default userSlice.reducer


// builder.addCase(checkPhoneForApprove.pending, (state) => {
//     state.errCheckPhoneRes = "";
//     state.fetchingCheckPhoneRes = true;
// });
// builder.addCase(
//     checkPhoneForApprove.rejected,
//     (state, action: PayloadAction<any>) => {
//
//         state.errCheckPhoneRes = action.payload;
//         state.fetchingCheckPhoneRes = false;
//         state.checkPhoneRes = null;
//         console.log("rejected")
//     }
// );
// builder.addCase(
//     checkPhoneForApprove.fulfilled,
//     (state, action: PayloadAction<any>) => {
//         state.errCheckPhoneRes = "";
//         state.fetchingCheckPhoneRes = false;
//         state.checkPhoneRes = action.payload;
//         // state.visibleCheckCodeModal = true;
//         state.visibleSignUpModal = false;
//         console.log(action.payload)
//     }
// );
// //проверка кода
// builder.addCase(checkPhoneCodeForApprove.pending, (state) => {
//     state.checkPhoneRes = null;
//     state.checkPhoneForChangeItLoading = true;
// });
// builder.addCase(
//     checkPhoneCodeForApprove.rejected,
//     (state, action: PayloadAction<any>) => {
//         state.checkPhoneRes = null;
//         state.checkPhoneForChangeItLoading = false;
//     }
// );
// builder.addCase(
//     checkPhoneCodeForApprove.fulfilled,
//     (state, action: PayloadAction<any>) => {
//         state.errCheckPhoneRes = "";
//         state.fetchingCheckPhoneRes = false;
//         state.checkPhoneRes = action.payload;
//         // state.visibleCheckCodeModal = true;
//         state.visibleSignUpModal = false;
//         console.log(action.payload)
//     }
// );