import React from "react";
import { IconProps } from "../../types/appTypes";
import style from "../containerComponents/CartContianer/CartIcon.module.scss"
const CartIcon: React.FC<IconProps> = ({
                                           className,
                                           color,
                                           height,
                                           width,
                                       }: IconProps): JSX.Element => {
    return (
        <svg
            width={width ? width : "25"}
            height={height ? height : "25"}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.55225 7.53125H21.4481V20.4583C21.4481 21.2485 21.1342 22.0062 20.5755 22.5649C20.0168 23.1236 19.259 23.4375 18.4689 23.4375H6.53141C5.74129 23.4375 4.98353 23.1236 4.42482 22.5649C3.86612 22.0062 3.55225 21.2485 3.55225 20.4583V7.53125Z"
                stroke={color ? color : "white"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.53125 10.5104V6.53125C7.53125 5.21346 8.05474 3.94963 8.98656 3.01781C9.91838 2.08599 11.1822 1.5625 12.5 1.5625C13.8178 1.5625 15.0816 2.08599 16.0134 3.01781C16.9453 3.94963 17.4688 5.21346 17.4688 6.53125V10.5104"
                stroke={color ? color : "white"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default CartIcon;
