import React from "react";
import logo1 from "../imgs/смит 1.png";
import { Link } from "react-router-dom";
import img1 from "../imgs/page_not_found1.png"
import img2 from "../imgs/page_not_found2.png"
import img3 from "../imgs/page_not_found3.png"
import styles from "./PageNotFound.module.scss"

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.thick_text}>Упс...</p>
        <p className={styles.thick_text}>что-то пошло не так</p>
        <p className={styles.text}>Предлагаем перейти на <Link to="/">главную</Link></p>
      </div>
      <div className={styles.images_container}>
        <img className={styles.img1} src={img1} alt=""/>
        <img className={styles.img2} src={img2} alt=""/>
        <img className={styles.img3} src={img3} alt=""/>
      </div>
        <Link to="/" className={styles.button}>На главную</Link>
    </div>
  );
};

export default PageNotFound;
