import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {
    LoginFormType,
    RegisterFormType,
    chedckphoneType,
} from "../types/appTypes";
import {notification} from "antd";
import $authHost from "./authApi";
import {orderFormDataAC} from "../store/ordersSlice";

export const registerApi = createAsyncThunk(
    "user/register",
    async (
        {password, first_name, last_name, username,  birthdate, phone, code}: RegisterFormType,
        {rejectWithValue, getState, fulfillWithValue}
    ) => {
        // @ts-ignore
        const {checkPhoneRes} = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
                code,
                phone: checkPhoneRes.id,
            })
            .then(async (res) => {
                if (res.data?.is_valid_code) {
                    console.log("код прошел проверку")
                    const data = await axios
                        .post(`${process.env.REACT_APP_API_URL}/users/`, {
                            username,
                            password,
                            phone,
                            first_name,
                            last_name,
                            birthdate
                        })
                        .then(async (response) => {
                            console.log("данные прошли регистрацию")
                            const tokenData = await axios
                                .post(`${process.env.REACT_APP_API_URL}/jwt/create`, {
                                    phone: checkPhoneRes.id,
                                    password,
                                })
                                .then(async (resp) => {
                                    console.log("токен получен")
                                    const userInfo = await axios
                                        .get(`${process.env.REACT_APP_API_URL}/users/me`, {
                                            headers: {Authorization: `Bearer ${resp.data?.access}`},
                                        })
                                        .then((respon) => respon.data)
                                        .catch((err) => {
                                            throw new Error("Пользователь не найден!");
                                        });

                                    return {...resp.data, userInfo};
                                })
                                .catch((err: any) => {
                                    console.log("токен не получен")
                                    if (err?.response?.status === 400) {
                                        if (err?.response?.data?.password) {
                                            throw new Error(
                                                err?.response?.data?.password?.find((e: any) => e)
                                            );
                                        } else {
                                            throw new Error("Не валидный номер телефона!");
                                        }
                                    } else {
                                        throw new Error("Ошибка регистрации!");
                                    }
                                });

                            return tokenData;
                        })
                        .catch((err) => {
                            console.log("пользователь не зарег")
                            if (err?.response?.status === 400) {
                                if (
                                    err?.response?.data.phone?.find(
                                        (e: string) => e === "Значения поля должны быть уникальны."
                                    )
                                ) {
                                    throw new Error(
                                        "Пользователь с таким номером уже зарегистрирован!"
                                    );
                                } else {
                                    throw new Error("Ошибка регистрации!");
                                }
                            } else {
                                throw new Error("Ошибка регистрации!");
                            }
                        });
                    return data;
                } else {
                    notification.error({
                        message: "Ошибка при регистрации!",
                        description: "Не валидный код!",
                    });
                    throw new Error("invalid code");
                }
            })
            .catch((err: any) => {
                console.log("код не прошел проверку")
                return rejectWithValue(err?.message);
            });
    }
);

export const checkPhoneForRegister = createAsyncThunk(
    "user/checkphone",
    async ({phone, is_send_sms}: chedckphoneType, {rejectWithValue}) => {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `+992${phone}`,
                is_send_sms,
            })
            .then((res) => {
                if (res.data.is_black_list) {
                    console.log("rejected")
                    throw new Error("Номер находится в черном списке");
                } else {
                    console.log("!rejected")
                    return res.data;
                }
            })
            .catch((err) => {
                if (err?.message === "Номер находится в черном списке") {
                    notification.error({message: err?.message});
                } else {
                    notification.error({message: "Введите корректный номер телефона."});
                }
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);

export const loginApi = createAsyncThunk(
    "user/login",
    async (
        {password, phone}: LoginFormType,
        {rejectWithValue, getState, dispatch}
    ) => {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `+992${phone}`,
                is_send_sms: false,
            })
            .then(async (res) => {
                if (res.data.is_black_list) {
                    notification.error({
                        message: "Номер находится в черном списке",
                    });
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    const data = await axios
                        .post(`${process.env.REACT_APP_API_URL}/jwt/create`, {
                            phone: res.data?.id,
                            password,
                        })
                        .then(async (response) => {
                            const userInfo = await axios
                                .get(`${process.env.REACT_APP_API_URL}/users/me`, {
                                    headers: {Authorization: `Bearer ${response.data?.access}`},
                                })
                                .then((respon) => {
                                    return respon.data;
                                })
                                .catch((err) => {
                                    throw new Error("Пользователь не найден!");
                                });
                            return {...response.data, userInfo};
                        })
                        .catch((err: any) => {
                            throw new Error(
                                "Неправильный пароль или пользователь не зарегистрирован"
                            );
                        });

                    return data;
                }
            })
            .catch((error: any) => {
                if (error?.status) {
                    notification.error({message: "Введите корректный номер телефона."});
                } else {
                    notification.error({
                        message: "Ошибка при входе!",
                        description: error?.message,
                    });
                }
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);

export const checkPhoneForChangeIt = createAsyncThunk(
    "user/checkPhoneForChangeIt",
    async ({phone, is_send_sms}: chedckphoneType, {rejectWithValue}) => {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `+992${phone}`,
                is_send_sms,
            })
            .then((res) => {
                if (res.data.is_black_list) {
                    notification.error({message: "Номер находится в черном списке"});
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    return res.data;
                }
            })
            .catch((err) => {
                notification.error({message: "Введите корректный номер телефона."});
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);

export const checkPhoneCodeForChangeItApi = createAsyncThunk(
    "user/checkPhoneCodeForChengeIt",
    async ({code}: any, {rejectWithValue, getState}: any) => {
        const {checkPhoneRes, accesstoken} = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
                code,
                phone: checkPhoneRes.id,
            })
            .then((res) => {
                if (res.data?.is_valid_code) {
                    return res.data;
                } else {
                    notification.error({message: "Не валидный код!"});
                    throw new Error("invalid code");
                }
            })
            .catch((err) => {
                notification.error({message: "Введите корректный номер телефона."});
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);

export const changePhoneNumApi = createAsyncThunk(
    "user/changePhone",
    async ({currentPassword}: any, {getState, rejectWithValue}: any) => {
        const {checkPhoneRes, accesstoken} = getState().userSlice;
        return await axios
            .post(
                `${process.env.REACT_APP_API_URL}/users/set_phone/`,
                {
                    new_phone: checkPhoneRes.id,
                    current_password: currentPassword,
                },
                {headers: {Authorization: `Bearer ${accesstoken}`}}
            )
            .then(async (res) => {
                return await axios
                    .get(`${process.env.REACT_APP_API_URL}/users/me`, {
                        headers: {Authorization: `Bearer ${accesstoken}`},
                    })
                    .then((respon) => {
                        notification.success({message: "Номер успешно изменен!"});
                        return respon.data;
                    })
                    .catch((err) => {
                        // if()
                        throw new Error("err");
                    });
            })
            .catch((err: any) => {
                // notification.error({
                //   message: err.message ? err.message : "Ошибка при изменении номера",
                // });
                notification.error({
                    message: "Не правельный пароль",
                });
                return rejectWithValue("err");
            });
    }
);

export const checkPhoneForChangeUserDataApi = createAsyncThunk(
    "user/checkphoneForChengeUserData",
    async ({is_send_sms}: any, {rejectWithValue, getState}: any) => {
        const {
            userInfo: {phone_number},
        } = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `${phone_number}`,
                is_send_sms,
            })
            .then((res) => {
                if (res.data.is_black_list) {
                    notification.error({message: "Номер находится в черном списке"});
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    return res.data;
                }
            })
            .catch((err) => {
                notification.error({message: "Введите корректный номер телефона."});
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);
export const changeUserDataApi = createAsyncThunk(
    "user/changeUserData",
    async (data: any, {getState, rejectWithValue}: any) => {
        const {checkPhoneRes, accesstoken, userInfo} = getState().userSlice;
        const {code, ...restData} = data;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: userInfo.phone_number,
                is_send_sms: false,
            })
            .then(async (response) => {
                if (response.data.is_black_list) {
                    notification.error({message: "Номер находится в черном списке"});
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    return await axios
                        .patch(
                            `${process.env.REACT_APP_API_URL}/users/me/`,
                            {
                                ...restData,
                                // _,
                            },
                            {
                                headers: {Authorization: `Bearer ${accesstoken}`},
                                data: restData,
                            }
                        )
                        .then(async (res) => {
                            return await $authHost
                                .get("/users/me/")
                                .then((res) => {
                                    notification.success({
                                        message: "Данные пользователя успешно изменены!",
                                    });
                                    return res.data;
                                })
                                .catch((err) => {
                                    return rejectWithValue("err");
                                });
                        })
                        .catch((err: any) => {
                            return rejectWithValue("err");
                        });
                }
            })
            .catch((err) => {
                return rejectWithValue("errr");
            });
    }
);

export const changePasswordApi = createAsyncThunk(
    "user/changePassowrd",
    async (formData: any, {rejectWithValue, getState}: any) => {
        const {checkPhoneRes, userInfo} = getState().userSlice;
        // return await axios
        // .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
        //   code: code,
        //   phone: userInfo.phone,
        // })
        // .then(async (response) => {
        return await $authHost
            .post(`/users/set_password/`, {
                new_password: formData.newPassword,
                current_password: formData.currentPassword,
            })
            .then((res) => {
                return res.data;

            })
            .catch((err: any) => {
                if (err?.response?.status === 400) {
                    if (err?.response?.data?.current_password) {
                        return rejectWithValue("вы ввели неправильный текущий пароль");
                    } else if (err?.response?.data?.new_password) {
                        return rejectWithValue(
                            `поле новый пароль: ${err?.response?.data?.new_password?.find(
                                (e: string) => e
                            )}`
                        );
                    } else {
                        return rejectWithValue("Ошибка при изменении пароля!");
                    }
                } else {
                    return rejectWithValue("Ошибка при изменении пароля!");
                }
            });
    }
);

export const checkPhoneForSetPassword = createAsyncThunk(
    "user/checkPhoneForSetPassword",
    async (_, {rejectWithValue, getState}: any) => {
        const {userInfo} = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `${userInfo.phone_number}`,
                is_send_sms: true,
            })
            .then((res) => {
                if (res.data.is_black_list) {
                    notification.error({message: "Номер находится в черном списке"});
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    return res.data;
                }
            })
            .catch((err) => {
                notification.error({message: "Введите корректный номер телефона."});
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);
export const checkPhoneCodeForSetPassword = createAsyncThunk(
    "user/checkPhoneCodeForSetPassword",
    async (formData: any, {rejectWithValue, getState}: any) => {
        const {checkPhoneRes, userInfo} = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
                code: formData,
                phone: userInfo.phone,
                // phone: checkPhoneRes.id,
            })
            .then((r) => {
                if (r.data?.is_valid_code) {
                    return r.data;
                } else {
                    notification.error({message: "Не валидный код!"});
                    throw new Error("invalid code");
                }
            })
            .catch((err) => {
                return rejectWithValue("invalid code");
            });
    }
);
export const checkPhoneForResetPassword = createAsyncThunk(
    "user/checkPhoneForResetPassword",
    async (formData: any, {rejectWithValue, getState}: any) => {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `+992${formData}`,
                is_send_sms: true,
            })
            .then((res) => {
                debugger
                if (res.data.is_black_list) {
                    notification.error({message: "Номер находится в черном списке"});
                    return rejectWithValue("Номер находится в черном списке");
                } else {
                    return res.data;
                }
            })
            .catch((err) => {
                debugger
                notification.error({message: "Введите корректный номер телефона."});
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);
export const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async (formData: any, {rejectWithValue, getState}: any) => {
        const {checkPhoneForResetPasswordData} = getState().userSlice;
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
                code: formData.code,
                phone: checkPhoneForResetPasswordData.id,
            })
            .then(async (response) => {
                debugger
                if (response.data?.is_valid_code) {
                    return await axios
                        .post(`${process.env.REACT_APP_API_URL}/users/reset_password/`, {
                            phone: checkPhoneForResetPasswordData.id,
                        })
                        .then((res) => {
                            return res.data;
                        })
                        .catch((err: any) => {
                            throw new Error("Пользователь не найден!");
                        });
                } else {
                    notification.error({message: "Не валидный код!"});
                    throw new Error("invalid code");
                }
            })
            .catch((err) => {
                debugger
                if (err.response?.status === 400) {
                    notification.error({message: "Введите корректный номер телефона."});
                    return rejectWithValue("Введите корректный номер телефона.");
                } else {
                    notification.error({message: err.message});
                    return rejectWithValue("Пользователь не найден!");
                }
            });
    }
);

// export const setPassword = createAsyncThunk(
//   "user/resetPassword",
//   async (formData: any, { rejectWithValue, getState }: any) => {
//     const { checkPhoneRes, userInfo } = getState().userSlice;

//     return await $authHost
//       .post(`/users/set_password/`, {
//         new_password: formData.newPassword,
//         current_password: formData.currentPassword,
//       })
//       .then((res) => {
//         return res.data;
//       })
//       .catch((err: any) => {
//         return rejectWithValue("rer");
//       });
//   }
// );

export const getUserData = createAsyncThunk(
    "users/getData",
    async (_, {rejectWithValue, getState}: any) => {
        return await $authHost
            .get("/users/me/")
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return rejectWithValue("err");
            });
    }
);


export const checkPhoneForApprove = createAsyncThunk(
    "user/checkphoneforapprove",
    async ({phone, is_send_sms}: chedckphoneType, {rejectWithValue}) => {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/checkphone/`, {
                phone: `+992${phone}`,
                is_send_sms,
            })
            .then((res) => {
                debugger
                if (res.data.is_black_list) {
                    console.log("rejected")
                    throw new Error("Номер находится в черном списке");
                } else {
                    console.log("!rejected")
                    return res.data;
                }
            })
            .catch((err) => {
                debugger
                if (err?.message === "Номер находится в черном списке") {
                    notification.error({message: err?.message});
                } else {
                    notification.error({message: "Введите корректный номер телефона."});
                }
                return rejectWithValue("Введите корректный номер телефона.");
            });
    }
);

export const checkPhoneCodeForApprove = createAsyncThunk(
    "user/checkPhoneCodeForApprove",
    async ({ code, phone }: any, { rejectWithValue, getState }: any) => {
        const { checkPhoneRes, accesstoken } = getState().userSlice;
        console.log("checkPhoneCodeForApprove start");

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
                code,
                phone: phone.id,
            });

            if (res.data?.is_valid_code) {
                debugger
                try {
                    debugger
                    const resetRes = await axios.post(
                        `${process.env.REACT_APP_API_URL}/users/reset_password/`,
                        {
                            phone: phone.id,
                        }
                    );
                    return resetRes.data;
                } catch (e: any) {
                    debugger
                    console.log(e.message)
                }
            } else {
                debugger
                notification.error({ message: "Не валидный код!" });
                throw new Error("invalid code");
            }
        } catch (err: any) {
            debugger
            notification.error({ message: "Введите корректный номер телефона." });
            return rejectWithValue("Введите корректный номер телефона.");
        }
    }
);



